<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="my-3" v-if="loggedIn">
        <b-col>
          <b-card no-body class="overflow-hidden" style="">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body title="add new place">
                  <b-card-text>
                    <b-row class="text-right">
                      <b-col cols="4" class="text-left">city</b-col>
                      <b-col cols="8"
                        ><b-form-input placeholder="city" v-model="city"></b-form-input
                      ></b-col>
                    </b-row>

                    <b-row class="text-right mt-3">
                      <b-col cols="4" class="text-left">country</b-col>
                      <b-col cols="8"
                        ><b-form-input placeholder="country" v-model="country"></b-form-input
                      ></b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
                <footer>
                  <b-icon
                    v-if="processing"
                    icon="arrow-counterclockwise"
                    animation="spin-reverse-pulse"
                    font-scale="3"
                  ></b-icon>
                  <b-button
                    v-if="!processing"
                    class="my-3"
                    variant="success"
                    v-on:click="addLocation"
                    >add this location</b-button
                  >
                </footer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="!loggedIn">
        <b-col>
          <p>Logging in first would be good idea!</p>
          <b-button to="/login" class="m-1" variant="success">Login</b-button>
          <b-button to="/register" class="m-1" variant="outline-primary">Register</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SellItem",
  props: {
    msg: String,
  },

  data: function() {
    return {
      city: null,
      country: null,

      location: null,
      seller: localStorage.getItem("username"),
      loggedIn: false,

      error: false,
      errorMsg: "",
      processing: false,
      errorColor: "",

      locations: [],
      currencies: [],
      cities: [],
      showAddLocationBox: true,
      countries: [],

      serverPath: process.env.VUE_APP_SERVER,
    };
  },

  mounted: async function() {
    if (localStorage.getItem("username")) {
      this.loggedIn = true;
    }
  },

  methods: {
    validateForm() {
      if (!this.city) {
        this.errorMsg = "missing city";
        return false;
      }

      if (!this.country) {
        this.errorMsg = "missing country";
        return false;
      }

      return true;
    },

    addLocation() {
      if (!this.validateForm()) {
        this.error = true;

        this.errorColor = "danger";
        window.scrollTo(0, 0);
        return;
      }

      this.error = false;
      this.processing = true;
      var options = {
        city: this.city,
        country: this.country,
      };

      axios
        .post(process.env.VUE_APP_SERVER + "/locations", options, {
          headers: {
            // 'application/json' is the modern content-type for JSON, but some
            // older servers may use 'text/json'.
            // See: http://bit.ly/text-json
            Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
          this.processing = false;
          this.error = true;
          this.errorMsg = "this location is now added!";
          this.errorColor = "success";
          window.location.replace("/sell");
        })
        .catch((error) => {
          this.processing = false;
          this.error = true;
          this.errorMsg = error.message;
          this.errorColor = "danger";
          console.log(error);
          //handle error
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
/* a {
 color: #42b983;
} */
</style>
