<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="my-3" v-if="loggedIn && !published">
        <b-col>
          <b-card no-body class="overflow-hidden" style="">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body title="describe your stuff">
                  <b-card-text>
                    <b-row>
                      <b-col cols="4" class="text-left">Location</b-col>
                      <b-col cols="8">
                        <b-form-select
                          class="mt-1"
                          v-model="location"
                          :options="locations"
                          v-on:change="updateLocation"
                        ></b-form-select>
                      </b-col>
                    </b-row>

                    <b-row class="text-right">
                      <b-col cols="12">
                        <small><i>first make sure your location is there</i></small>&nbsp;
                        <b-link to="/addlocation">add new location</b-link>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col cols="4" class="text-left">Title</b-col>
                      <b-col cols="8"
                        ><b-form-input
                          col="8"
                          class=""
                          v-model="title"
                          placeholder="name your item"
                        ></b-form-input
                      ></b-col>
                    </b-row>

                    <b-col cols="12" class="mt-3">
                      <!-- <b-nav-item to="/item/1"> -->

                      <!-- <b-card-img
                        v-bind:src="imageUrl"
                        alt="Image"
                        class="rounded-0 w-50"
                      ></b-card-img> -->

                      <!-- </b-nav-item> -->
                      <span v-if="item">
                        <b-card-img
                          style="max-height: 50vh; max-width: 50vw;"
                          v-for="(pic, i) in item.pictures"
                          v-bind:key="i"
                          v-bind:src="serverPath + pic.url"
                          alt="Image"
                          class="rounded-0 w-50 p-1"
                        ></b-card-img>
                      </span>

                      <!-- Styled -->
                      <b-form-file
                        v-model="file1"
                        multiple
                        :state="Boolean(file1)"
                        placeholder="add pic"
                        drop-placeholder="add pic"
                      ></b-form-file>
                      <small> <i>you can add picture later. only add 1 pic at a time. max 4 pics</i> </small>

                      <!-- <div class="mt-3">Selected file: {{ file1 ? file1.name : "" }}</div> -->

                      <!-- <b-button v-if="!processing" class="my-3" variant="success" v-on:click="uploadPic"
            >upload pic</b-button
          > -->
                    </b-col>

                    <p class="text-left">
                      Description
                      <b-form-textarea
                        class=""
                        v-model="description"
                        placeholder="description"
                        rows="5"
                      ></b-form-textarea>
                    </p>
                    <b-row>
                      <b-col cols="4" class="text-left">Price</b-col>
                      <b-col cols="8"
                        ><b-form-input
                          class=""
                          v-model="price"
                          type="number"
                          placeholder=""
                        ></b-form-input
                      ></b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="4" class="text-left"> Currency</b-col>
                      <b-col cols="8">
                        <b-form-select
                          class="mt-1"
                          v-model="currency"
                          :options="currencies"
                          v-on:change="updateCurrency"
                        ></b-form-select>
                      </b-col>
  </b-row>


                           <b-row>
                      <b-col cols="4" class="text-left">Youtube Video Link</b-col>
                      <b-col cols="8">
                        <b-form-input
                          class="mt-1"
                          v-model="youtubelink"
                          placeholder="example: https://www.youtube.com/watch?v=Nyfz_weESKg"
                        ></b-form-input>
                      </b-col>


                    </b-row>
                  </b-card-text>
                </b-card-body>
                <footer>
                  <b-icon
                    v-if="processing"
                    icon="arrow-counterclockwise"
                    animation="spin-reverse-pulse"
                    font-scale="3"
                  ></b-icon>
                  <b-button
                    v-if="!processing"
                    class="my-3"
                    variant="success"
                    v-on:click="sellItem"
                    >{{ publishButton }}</b-button
                  >
                </footer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="!loggedIn">
        <b-col>
          <p>Logging in first would be good idea!</p>
          <b-button to="/login" class="m-1" variant="success">Login</b-button>
          <b-button to="/register" class="m-1" variant="outline-primary">Register</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SellItem",
  props: {
    msg: String,
  },

  data: function() {
    return {
      file1: null,
      file2: null,
      item: null,
      title: null,
      description: null,
      price: null,
      youtubelink: null,
      city: 1,
      country: 1,
      currency: 1,
      currencycode: "EUR",
      currencysymbol: "€",
      location: null,
      seller: localStorage.getItem("username"),
      loggedIn: false,
      publishButton: "Publish",
      error: false,
      errorMsg: "",
      processing: false,
      errorColor: "",
      published: false,
      locations: [],
      currencies: [],
      cities: [],
      showAddLocationBox: true,
      countries: [],
      selected: null,
      imageUrl: "/nopic.jpeg",
      serverPath: process.env.VUE_APP_SERVER,
      options: [
        { value: null, text: "Country" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
    };
  },

  mounted: async function() {
    if (localStorage.getItem("username")) {
      this.loggedIn = true;
    }

    await this.getCurrencies();
    await this.getLocations();

    if (this.$route.params.id) {
      this.getItem();
      this.publishButton = "Update";
    }
  },

  methods: {
    addLocation() {
    
      this.showAddLocationBox = true;
    },
    uploadPic(itemId) {
      const formData = new FormData();

      formData.append("files", this.file1[0]);
      formData.append("ref", "items"); // optional, you need it if you want to link the image to an entry
      formData.append("refId", itemId); // optional, you need it if you want to link the image to an entry
      formData.append("field", "pictures"); // optional, you need it if you want to link the image to an entry

      axios
        .post(`${process.env.VUE_APP_SERVER}/upload`, formData)
        .then((response) => {
          this.processing = false;
          this.error = true;
          this.errorMsg = "Your stuff is published!";
          this.errorColor = "success";
          this.published = true;
          window.location.replace("/item/" + itemId);

          //after success
        })
        .catch((error) => {
          console.log(error);
          //handle error
        });
    },

    updateLocation() {
      this.locations.forEach((el) => {
        if (el.value == this.location) {
          this.city = el.city;
          this.country = el.country;
        }
      });
    },
    updateCurrency() {
      this.currencies.forEach((el) => {
        if (el.value == this.currency) {
          this.currencycode = el.code;
          this.currencysymbol = el.symbol;
        }
      });
    },

    getItem() {
      axios
        .get(process.env.VUE_APP_SERVER + "/items/" + this.$route.params.id, {})
        .then((response) => {
          this.item = response.data;
          this.title = response.data.title;
          this.description = response.data.description;
          this.price = response.data.price;
          this.city = response.data.city;
          this.country = response.data.country;

          this.currencycode = response.data.currencycode;
          this.currencysymbol = response.data.currencysymbol;
          if (response.data.pictures.length > 0) {
            this.imageUrl = process.env.VUE_APP_SERVER + response.data.pictures[0].url;
          }

          this.locations.forEach((el) => {
            if (el.city == this.city && this.country == this.country) {
              this.location = el.value;
            }
          });

          this.currencies.forEach((el) => {
            if (el.code == this.currencycode) {
              this.currency = el.value;
            }
          });
        });
    },

    async getCurrencies() {
      await axios.get(process.env.VUE_APP_SERVER + "/currencies", {}).then((response) => {
        response.data.forEach((el) => {
          this.currencies.push({
            value: el.id,
            text: el.symbol + " " + el.code,
            code: el.code,
            symbol: el.symbol,
          });
        });
      });
    },

    async getLocations() {
      await axios.get(process.env.VUE_APP_SERVER + "/locations", {}).then((response) => {
        response.data.forEach((el) => {
          this.locations.push({
            value: el.id,
            text: el.city + ", " + el.country,
            city: el.city,
            country: el.country,
          });
        });
      });
    },

    validateForm() {
      if (!this.title) {
        this.errorMsg = "missing title";
        return false;
      }

      if (!this.description) {
        this.errorMsg = "missing description";
        return false;
      }
      if (this.price == "" || this.price == null) {
        this.price = 0;
        // this.errorMsg = "missing price";
      }
      if (!this.location) {
        this.errorMsg = "missing location";
        return false;
      }
      if (!this.currency) {
        this.errorMsg = "missing currency";
        return false;
      }

      if (!this.description) {
        this.errorMsg = "missing description";
        return false;
      }

      return true;
    },

    itemSaved(itemid) {
      if (this.file1 && this.item.pictures.length < 4) {
        this.uploadPic(itemid);
      } else {
        this.processing = false;
        this.error = true;
        this.errorMsg = "Your stuff is published!";
        this.errorColor = "success";
        this.published = true;
        window.location.replace("/item/" + itemid);
      }
    },

    sellItem() {
      if (!this.validateForm() || !this.currencycode || !this.currencysymbol || !this.location) {
        this.error = true;
        // this.errorMsg = "Missing something";
        this.errorColor = "danger";
        window.scrollTo(0, 0);
        return;
      }

      this.error = false;
      this.processing = true;
      var options = {
        title: this.title,
        description: this.description,
        price: this.price,
        city: this.city,
        country: this.country,
        currencycode: this.currencycode,
        currencysymbol: this.currencysymbol,
        youtubelink: this.youtubelink,
        seller: { id: localStorage.getItem("sellerid") },
        live: true,
      };

      if (this.$route.params.id) {
        axios
          .put(process.env.VUE_APP_SERVER + "/items/" + this.$route.params.id, options, {
            headers: {
              // 'application/json' is the modern content-type for JSON, but some
              // older servers may use 'text/json'.
              // See: http://bit.ly/text-json
              Authorization: "Bearer " + localStorage.getItem("usertoken"),
            },
          })
          .then((response) => {
            this.item = response.data;

            this.itemSaved(response.data.id);
          });
        return;
      } else {
        axios
          .post(process.env.VUE_APP_SERVER + "/items", options, {
            headers: {
              // 'application/json' is the modern content-type for JSON, but some
              // older servers may use 'text/json'.
              // See: http://bit.ly/text-json
              Authorization: "Bearer " + localStorage.getItem("usertoken"),
            },
          })
          .then((response) => {
            this.item = response.data;

            this.itemSaved(response.data.id);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
/* a {
 color: #42b983;
} */
</style>
