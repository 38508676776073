<template>
  <div class="">
    <Item />
  </div>
</template>

<script>
// @ is an alias to /src
import Item from "@/components/Item.vue";

export default {
  name: "Home",
  components: {
    Item,
  },
};
</script>
