<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-form v-if="!loggedIn">
        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">email or username</label>
          </b-col>
          <b-col cols="9">
            <b-form-input class="" v-model="email" placeholder="Enter your email"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">password</label>
          </b-col>
          <b-col cols="9">
            <b-form-input type="password" v-model="password" placeholder="Password"></b-form-input>
          </b-col>
        </b-row>

        <b-icon
          v-if="processing"
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          font-scale="3"
        ></b-icon>
        <b-button v-if="!processing" class="my-3" variant="success" v-on:click="login"
          >Login</b-button
        >
      </b-form>

      <b-row v-if="loggedIn">
        <b-col>
          <p>you are already logged in</p>
        </b-col>
      </b-row>
    </b-container>

    <!-- <div>
      <div class="flex items-center justify-center h-screen">
        <div class="hidden sm:block w-1/2 bg-cover h-screen" style="background: url(newFood.png)">
          <div class="bg-blue-800 w-full h-screen bg-opacity-20"></div>
        </div>
        <div class="sm:w-1/2">
          <div class="p-5 w-4/5 mx-auto text-left font-raleway">
            <div class="text-left mb-7">
              <router-link to="/">
                HOME
              </router-link>
            </div>
            <h1 class="font-bold text-left font-montserrat text-4xl sm:text-6xl mb-7">
              Sign Up. To. Join Recipee
            </h1>
            <p v-show="error" class="text-sm text-red-500">{{ errorMsg }}</p>
            <form @submit="register22">
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Name</h1>
                <input
                  type="text"
                  v-model="name"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Email</h1>
                <input
                  type="email"
                  v-model="email"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Password</h1>
                <input
                  type="password"
                  v-model="password"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Username</h1>
                <input
                  type="text"
                  v-model="username"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>

              <button
                type="submit"
                :disabled="name.length < 1 || password.length < 4 || username.length < 1"
                class=" "
              >
                Sign Up
              </button>
            </form>
          </div>


        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MagicLink",
  props: {
    msg: String,
  },

  data: function() {
    return {
      item: {},
      email: "",
      password: "",
      error: false,
      errorMsg: `An Error occurred, please try again`,
      processing: false,
      errorColor: "",
      loggedIn: false,
    };
  },

  mounted: function() {
    // this.sendMagicLink();
    if (localStorage.getItem("usertoken")) {
      this.loggedIn = true;
    }
  },

  methods: {
    addSellerAccountToUser(userid, sellerid) {
      var options = {
        seller: sellerid,
      };
      axios
        .put(process.env.VUE_APP_SERVER + "/users/" + userid, options, {
          headers: {
            // 'application/json' is the modern content-type for JSON, but some
            // older servers may use 'text/json'.
            // See: http://bit.ly/text-json
            Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
          this.processing = false;

          if (response.status == 200) {
            this.error = true;
            this.errorMsg = "you are now logged in";
            this.errorColor = "success";

            window.location.replace("/account");
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response) {
          }
        });
    },

    async getSellerId(userid, username) {
      return await axios
        .get(process.env.VUE_APP_SERVER + "/sellers?username=" + username)
        .then((response) => {
          this.processing = false;

          if (response.status == 200) {
            localStorage.setItem("sellerid", response.data[0].id);

            this.addSellerAccountToUser(userid, response.data[0].id);
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response) {
          }

          this.error = true;
          this.errorColor = "danger";

          this.errorMsg = error.response.data.data[0].messages[0].message;

          console.log("Error: ", error);
          console.log("Error: ", error.response);
        });
    },

    async login(e) {
      this.processing = true;
      this.error = false;
      event.preventDefault();
      var options = {
        password: this.password,
        identifier: this.email,
      };
      axios
        .post(process.env.VUE_APP_SERVER + "/auth/local", options)
        .then((response) => {
          this.processing = false;

          if (response.status == 200) {
            
            this.error = true;
            this.errorMsg = "you are now logged in";
            this.errorColor = "success";
            localStorage.setItem("usertoken", response.data.jwt);
            localStorage.setItem("username", response.data.user.username);
            localStorage.setItem("userid", response.data.user.id);
            // localStorage.setItem("sellerid", response.data.user.seller.id);

            if (response.data.user.seller) {
              localStorage.setItem("sellerid", response.data.user.seller.id);
              window.location.replace("/account");
            } else {
              this.getSellerId(response.data.user.id, response.data.user.username);
            }
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response) {
          }

          // if (error.response.status == 400) {
          this.error = true;
          this.errorColor = "danger";

          this.errorMsg = error.response.data.data[0].messages[0].message;

          console.log("Error: ", error.response);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
