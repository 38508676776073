<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="my-3" align-h="center">
        <b-col class="">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body title="send me email">
                  <b-card-text>
                    <b-row class="my-3">
                      <b-col cols="3">
                        <label for="name">Your email</label>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="email"
                          placeholder="email@example.com"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="my-3">
                      <b-col cols="3">
                        <label for="name">Email</label>
                      </b-col>
                      <b-col cols="9">
                        <b-form-textarea
                          class=""
                          v-model="description"
                          placeholder="tell me"
                          rows="5"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>

                    <p class="text-left"></p>

                    <b-row class="my-3">
                      <b-col cols="3">
                        <label for="name">type 5 in human box</label>
                      </b-col>
                      <b-col cols="2">
                        <b-form-input v-model="captcha" placeholder=""></b-form-input>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
                <footer>
                  <b-icon
                    v-if="processing"
                    icon="arrow-counterclockwise"
                    animation="spin-reverse-pulse"
                    font-scale="3"
                  ></b-icon>
                  <b-button v-if="!processing" class="my-3" variant="success" v-on:click="sendEmail"
                    >Send</b-button
                  >
                </footer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SellItem",
  props: {
    msg: String,
  },

  data: function() {
    return {
      description: "",
      email: "",
      loggedIn: false,
      error: false,
      errorMsg: "",
      processing: false,
      errorColor: "",
      published: false,
      captcha: "",
    };
  },

  mounted: function() {},

  methods: {
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      }
      // alert("You have entered an invalid email address!");
      return false;
    },

    sendEmail() {
      if (!this.validateEmail()) {
        this.error = true;
        this.errorMsg = "bad email";
        this.errorColor = "danger";
        return;
      }

      if (!this.description) {
        this.error = true;
        this.errorMsg = "description missing";
        this.errorColor = "danger";
        return;
      }

      if (this.captcha !== "5") {
        this.error = true;
        this.errorMsg = "enter 5 in human box";
        this.errorColor = "danger";
        return;
      }

      this.error = false;
      this.processing = true;

      var options = {
        to: "contact@mywebartist.eu",
        subject: "website question",
        replyto: this.email,
        body: this.description,
      };

      axios.post(process.env.VUE_APP_SERVER + "/emails/sendemail", options, {}).then((response) => {
        this.processing = false;
        this.error = true;
        this.errorMsg = "Email is sent. I will answer you in 6 hrs!";
        this.errorColor = "success";
        this.published = true;
        // window.location.replace("/");
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
