<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-form>
        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">@username</label>
          </b-col>
          <b-col cols="9">
            <b-form-input disabled v-model="username" placeholder="Enter username"></b-form-input>
          </b-col>
        </b-row>

        <!-- <b-row class="my-3">
          <b-col cols="3">
            <label for="name">name</label>
          </b-col>
          <b-col cols="9">
            <b-form-input class="" v-model="name" placeholder="Enter your name"></b-form-input>
          </b-col>
        </b-row> -->

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">email</label>
          </b-col>
          <b-col cols="9">
            <b-form-input disabled v-model="email" placeholder="Enter your email"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3 text-right">
          <b-col cols="">
            <b-icon icon="power" aria-hidden="true" variant="info"></b-icon>
            <b-link to="/logout" class="m-1" type="" variant="info">Logout</b-link>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">address</label>
          </b-col>
          <b-col cols="9">
            <b-form-input
              v-model="address"
              v-on:input="activateUpdateProfileButton"
              placeholder="Address"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">phone with country code. example +31611223344</label>
          </b-col>
          <b-col cols="9">
            <b-form-input
              v-model="phone"
              v-on:input="activateUpdateProfileButton"
              placeholder="Phone"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">note put on all your ads</label>
          </b-col>
          <b-col cols="9">
            <b-form-textarea
              v-model="note"
              v-on:input="activateUpdateProfileButton"
              placeholder="note put on all your ads"
              rows="5"
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-icon
          v-if="processing"
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          font-scale="3"
        ></b-icon>
        <b-button
          v-if="!processing"
          v-bind:disabled="updateDisabled"
          class="my-3"
          variant="success"
          v-on:click="updateProfile"
          >Save</b-button
        >
      </b-form>

      <!-- <b-button v-on:click="updateProfile">Toggle Busy State</b-button> -->

      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">title</th>
            <!-- <th scope="col">description</th> -->
            <th scope="col">price</th>
            <th scope="col">status</th>
            <th scope="col">live</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" v-bind:key="i">
            <td>{{ item.title }}</td>
            <!-- <td>{{ item.description }}</td> -->
            <td>{{ item.price }}</td>
            <td>
              <a v-bind:href="'sell' + '/edit/' + item.id">edit</a>&nbsp;
              <a v-bind:href="'/item/' + item.id">view</a>
            </td>
            <td>
              <b-form-checkbox
                switch
                v-model="item.live"
                v-on:change="setLive(item.id, item.live)"
              ></b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

// import genUsername from "unique-username-generator";
// const genUsername = require("unique-username-generator");

export default {
  name: "MagicLink",
  props: {
    msg: String,
  },

  data: function() {
    return {
      item: {},
      email: "",
      name: "",
      phone: "",
      note: "",
      address: "",
      username: "",
      error: false,
      errorMsg: `An Error occurred, please try again`,
      errorColor: "",
      processing: false,
      isBusy: false,
      live: null,
      updateDisabled: true,
      items: [],
    };
  },

  watch: {
    address: function(val, oldVal) {
      // change of userinput, do something
      // this.activateUpdateProfileButton()
    },
  },

  mounted: function() {
    this.getItems();
    this.getSellerProfile();
    this.getSellerInfo();
  },

  created: function() {
    // this.activateUpdateProfileButton()
    // when the component has been created,
    // we replaced the original method with a debounced version
  },

  methods: {
    setLive(itemid, live) {
      var options = {
        live: live,
      };

      axios
        .put(process.env.VUE_APP_SERVER + "/items/" + itemid, options, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
     
        });
    },

    activateUpdateProfileButton() {
      this.updateDisabled = false;
    },

    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    getSellerInfo() {
      axios
        .get(process.env.VUE_APP_SERVER + "/sellers/" + localStorage.getItem("sellerid"), {
          headers: {
            // Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
          this.address = response.data.address;
          this.phone = response.data.phone;
          this.note = response.data.note;
        });
    },

    getItems() {
      var options = {};
      this.items = [];
      axios
        .get(
          process.env.VUE_APP_SERVER + "/items/?seller.id=" + localStorage.getItem("sellerid"),
          {}
        )
        .then((response) => {
          this.items = response.data;
        });
    },

    getSellerProfile() {
      var options = {};

      axios
        .get(process.env.VUE_APP_SERVER + "/users/me", {
          headers: {
            // 'application/json' is the modern content-type for JSON, but some
            // older servers may use 'text/json'.
            // See: http://bit.ly/text-json
            Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
          this.username = response.data.username;
          this.email = response.data.email;
        });
    },

    updateProfile() {
      this.error = false;
      this.processing = true;

      var options = {
        address: this.address,
        phone: this.phone,
        note: this.note,
      };

      axios
        .put(process.env.VUE_APP_SERVER + "/sellers/" + localStorage.getItem("sellerid"), options, {
          headers: {
            // 'application/json' is the modern content-type for JSON, but some
            // older servers may use 'text/json'.
            // See: http://bit.ly/text-json
            Authorization: "Bearer " + localStorage.getItem("usertoken"),
          },
        })
        .then((response) => {
          this.item = response.data;

          this.processing = false;
          this.error = true;
          this.errorMsg = "Your account is updated!";
          this.errorColor = "success";
          this.published = true;
          this.updateDisabled = true;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
