<template>
  <div class="">
    <AddLocation />
  </div>
</template>

<script>
// @ is an alias to /src
import AddLocation from "@/components/AddLocation.vue";

export default {
  name: "Home",
  components: {
    AddLocation,
  },
};
</script>
