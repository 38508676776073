<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible v-html="errorMsg">
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-form>
        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">@username</label>
          </b-col>
          <b-col cols="9">
            <b-form-input class="" v-model="username" placeholder="username"></b-form-input>
            <p class="text-left font-italic"><small>cannot be changed later</small></p>
          </b-col>
        </b-row>

        <!-- <b-row class="my-3">
          <b-col cols="3">
            <label for="name">name</label>
          </b-col>
          <b-col cols="9">
            <b-form-input class="" v-model="name" placeholder="Enter your name"></b-form-input>
          </b-col>
        </b-row> -->

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">email</label>
          </b-col>
          <b-col cols="9">
            <b-form-input class="" v-model="email" placeholder="email"></b-form-input>
            <p class="text-left font-italic">
              <small
                >will be kept private<br />enter correct email or account will be deleted</small
              >
            </p>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="3">
            <label for="name">password</label>
          </b-col>
          <b-col cols="9">
            <b-form-input type="password" v-model="password" placeholder="password"></b-form-input>
          </b-col>
        </b-row>
        <b-icon
          v-if="processing"
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          font-scale="3"
        ></b-icon>
        <b-button v-if="!processing" class="my-3" variant="success" v-on:click="register"
          >Register</b-button
        >
      </b-form>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
// import genUsername from "unique-username-generator";
const genUsername = require("unique-username-generator");

export default {
  name: "MagicLink",
  props: {
    msg: String,
  },

  data: function() {
    return {
      item: {},
      email: "",
      name: "",
      password: "",
      username: "",
      error: false,
      errorMsg: `An Error occurred, please try again`,
      errorColor: "",
      processing: false,
      token: null,
    };
  },

  mounted: function() {
    this.username = genUsername.generateUsername("");
  },

  methods: {
   
  

    createSellersAccount(userid, username) {
      var options = {
        userid: userid,
        username: username,
      };
      axios
        .post(process.env.VUE_APP_SERVER + "/sellers", options)
        .then((response) => {
          this.processing = false;

          if (response.status == 200) {
            this.error = true;
            this.errorMsg = "you are now registered <a  href='/login'>login</a>";
            this.errorColor = "success";
            // this.getToken(userid, response.data.id); // userid, sellerid
            // this.addSellerAccountToUser(response.data.id, userid)
            // window.location.replace("/");
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response) {
          }
        });
    },

    register(event) {
      this.processing = true;
      this.error = false;
      event.preventDefault();
      var options = {
        name: this.name,
        password: this.password,
        email: this.email,
        username: this.username,
      };
      axios
        .post(process.env.VUE_APP_SERVER + "/auth/local/register", options)
        .then((response) => {
          if (response.status == 200) {
            this.createSellersAccount(response.data.user.id, response.data.user.username);
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response) {
          }

          // if (error.response.status == 400) {
          this.error = true;
          this.errorColor = "danger";

          this.errorMsg = error.response.data.data[0].messages[0].message;
          //
          console.log("Error: ", error);
          console.log("Error: ", error.response);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
