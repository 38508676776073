<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert v-bind:show="error" v-bind:variant="errorColor" dismissible>
            {{ errorMsg }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col>
          <b-card no-body class="overflow-hidden" style=" ">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body v-bind:title="item.title">
                  <b-card-text>
                    <b-col v-if="item.youtubelink">
                      <div class="video-container">
                        <iframe
                          width="560"
                          height="315"
                          v-bind:src="createYoutubeLink(item.youtubelink)"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </b-col>

                    <b-col>
                      <b-card-img
                        style="max-height: 50vh; max-width: 50vw;"
                        v-for="(pic, i) in item.pictures"
                        v-bind:key="i"
                        v-bind:src="serverPath + pic.url"
                        alt="Image"
                        class="rounded-0 w-50 p-1 mb-3"
                      ></b-card-img>
                    </b-col>

                    {{ item.description }}
                  </b-card-text>
                </b-card-body>
                <footer v-if="item.city">
                  <span v-if="item.price > 0" style="color: red;">
                    {{ item.currencycode }} {{ item.price.toFixed(2) }}</span
                  >
                  <span v-else style="color: red;">FREE</span>
                  <br /><small>
                    <cite title="Source Title"> {{ item.city }}, {{ item.country }} </cite></small
                  >

                  <b-col>
                    <p class="text-left">
                      <b>sellers note:</b> <i>{{ item.seller.note }}</i>
                    </p>
                  </b-col>

                  <b-col>
                    <b-link class="my-3" variant="success" v-on:click="openContact"
                      >(seller contact)</b-link
                    >
                    <b-link class="my-3 ml-3" variant="success" v-on:click="openMessage"
                      >(message seller)</b-link
                    >
                  </b-col>

                  <b-card-footer v-if="item.seller.id == sellerid"
                    ><a v-bind:href="'../sell' + '/edit/' + item.id">edit</a></b-card-footer
                  >
                </footer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="showContact" style=" " class=" ">
        <b-col>
          <b-card no-body class="overflow-hidden" style=" ">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body title="seller contact">
                  <b-card-text>
                    <b-col v-if="item.seller.phone">
                      phone: {{ item.seller.phone }}
                      <b-link
                        v-bind:href="waNumber(item)"
                        target="_blank"
                        class="my-3 ml-3"
                        variant="success"
                      >
                        <b-img src="/wa.png" class="" style="max-width:25px"></b-img>
                      </b-link>
                    </b-col>
                    <b-col v-else>no phone given</b-col>
                    <b-col v-if="item.seller.address"> address: {{ item.seller.address }} </b-col>
                    <b-col v-else>no address given</b-col>
                    <b-col v-if="!item.seller.phone && !item.seller.address"
                      ><b-link class="my-3 ml-3" variant="success" v-on:click="openMessage"
                        >(message seller)</b-link
                      ></b-col
                    >
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="showMessageBox" style=" " class=" my-3">
        <b-col>
          <b-card no-body class="overflow-hidden" style=" ">
            <b-row no-gutters>
              <b-col md="12" to="/1">
                <b-card-body title="message seller">
                  <b-card-text>
                    <b-row class="my-3">
                      <b-col cols="3">
                        <label for="name">your contact where seller can reach you</label>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="contact"
                          placeholder="put your email or phone number in"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="my-3">
                      <b-col cols="3">
                        <label for="name">your message to seller</label>
                      </b-col>
                      <b-col cols="9">
                        <b-form-textarea
                          class=""
                          v-model="message"
                          placeholder="tell them"
                          rows="5"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                    <b-icon
                      v-if="processing"
                      icon="arrow-counterclockwise"
                      animation="spin-reverse-pulse"
                      font-scale="3"
                    ></b-icon>
                    <b-button
                      v-if="!processing"
                      class="my-3"
                      variant="success"
                      v-on:click="sendMessage"
                      >Send</b-button
                    >
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Item",
  props: {
    msg: String,
  },

  data: function() {
    return {
      item: {},
      imageUrl: "/nopic.jpeg",
      sellerid: null,
      contact: null,
      message: null,
      showContact: false,
      showMessageBox: false,
      processing: false,
      errorColor: "",
      error: null,
      errorMsg: "",
      serverPath: process.env.VUE_APP_SERVER,
    };
  },

  mounted: function() {
    this.sellerid = localStorage.getItem("sellerid");
    this.getItem();
  },

  watch: {},

  methods: {
    waNumber: function(item) {
      return (
        "https://api.whatsapp.com/send?phone=" +
        item.seller.phone +
        "&text=question about: " +
        item.title
      );
    },

    createYoutubeLink: function(item) {
      let ylink = item.split("v=");

      return "https://www.youtube.com/embed/" + ylink[1];
    },

    openContact() {
      this.showContact = true;
      this.contactButton = true;
    },
    openMessage() {
      this.showMessageBox = true;
    },

    getItem() {
      axios.get(process.env.VUE_APP_SERVER + "/items/" + this.$route.params.id).then((response) => {
        this.item = response.data;

        if (response.data.pictures.length > 0) {
          this.imageUrl = process.env.VUE_APP_SERVER + response.data.pictures[0].url;
        }
      });
    },

    sendMessage() {
      if (!this.contact) {
        this.error = true;
        this.errorMsg = "contact missing";
        this.errorColor = "danger";
        window.scrollTo(0, 0);
        return;
      }

      if (!this.message) {
        this.error = true;
        this.errorMsg = "message missing";
        this.errorColor = "danger";
        window.scrollTo(0, 0);
        return;
      }

      this.error = false;
      this.processing = true;

      var options = {
        userid: this.item.seller.userid,
        subject: "goodstuff: " + this.item.title,
        replyto: this.contact,
        body:
          "you got message about your ad http://" +
          window.location.host +
          "/item/" +
          this.item.id +
          "<br><br>message: " +
          this.message +
          "<br>contact: " +
          this.contact,
      };

      axios
        .post(process.env.VUE_APP_SERVER + "/emails/emailseller", options, {})
        .then((response) => {
          this.processing = false;
          this.error = true;
          this.errorMsg = "Emails is sent to seller";
          this.errorColor = "success";
          this.published = true;
          window.scrollTo(0, 0);
          // window.location.replace("/");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
